<template>
	<div>
		<el-card>
			<el-row>
				<el-button type="primary" @click="addLevel">新增轮播图</el-button>
			</el-row>
			<!-- 服务类型表单 -->
			<el-table :data="typeData" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="banner_id" label="ID"></el-table-column>
				<el-table-column label="图片">
					<template slot-scope="scope">
						<div>
							<el-avatar shape="square" size="large" :src="scope.row.img_path"></el-avatar>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="img_sort" label="排序"></el-table-column>
				<el-table-column label="是否上架">
					<template slot-scope="scope">
						<div>
							<el-switch v-model="scope.row.is_on_sale" @change="changeState(scope.row)"></el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="primary" plain size="small" @click="editLevel(scope.row)">编辑</el-button>
						<el-button type="danger" plain size="small" @click="removeLevel(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 新增对话框 -->
			<el-dialog :title="title" width="30%" :visible.sync="addVisible">
				<el-form ref="addForm" :model="addForm" label-width="100px" :rules="addFormRules">
					<el-row :gutter="10">
						<el-col :span="20">
							<el-form-item label="图片" prop="img_list">
                <div style="display:flex">
                  <div class="previewUpload">
                    <div v-show="addForm.img_list.length>0" class="previewItem" v-for="(item,index) in addForm.img_list" :key="index">
                      <img :src="item.img_path" alt="" @click="openUploadDialog(1,'cover')">
                      <i class="el-icon-close delIcon" @click="delCoverItem(index)"></i>
                    </div>
                    <div class="previewItems" v-if="addForm.img_list.length==0" @click="openUploadDialog(1,'cover')"><i class="el-icon-plus previewadd"></i></div>
                  </div>
                  <el-tooltip placement="right" class="tooltips">
                    <div slot="content">图片建议尺寸：750 * 400</div>
                    <i class="el-icon-question" style="font-size: 18px"></i>
                  </el-tooltip>
                </div>
              </el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="10">
						<el-col :span="20">
							<el-form-item label="排序值" prop="img_sort">
								<el-input v-model.number="addForm.img_sort" placeholder="请输入排序值" oninput="if(value<1)value=1"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-tooltip class="item" effect="dark" content="值越小,排序越靠前" placement="right">
								<i class="el-icon-question" style="font-size: 18px"></i>
							</el-tooltip>
						</el-col>
					</el-row>
				</el-form>
				<div class="center">
					<el-button type="default" @click="addVisible = false">取消</el-button>
					<el-button type="primary" @click="subAdd">确定</el-button>
				</div>
			</el-dialog>
			<Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Upload from '@/components/Upload/Upload.vue'
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Upload,Pagination
		},
		data() {
			const img_list=(rule,value,callback)=>{
		      if(this.addForm.img_list.length===0){
		        callback(new Error('请选择图片'))
		      }
		      callback()
		    }
			return {
				title: "新增轮播图",
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				addVisible: false,
				total: 0,
				addForm: {
					img_list: [],
					img_sort: 0,
				},
				addFormRules: {
					img_list: [{validator: img_list,trigger: 'blur'}],
					img_sort: [{
							required: true,
							message: "请输入排序序号",
							trigger: "blur"
						},
						{
							type: "number",
							message: "请输入数字"
						},
					]
				},
				typeData: [],
				chooseImgCount:0,
		      	pictureType:'',
		      	uploadVisible:false,
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getBannerList();
			}
		},
		methods: {
			getBannerList() {
				var url = 'service_banner/_list';
				let params = {
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.typeData = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//新增轮播图
			addLevel() {
				this.title = "新增轮播图";
				this.addForm = {img_list: [],img_sort: 0,};
				this.addVisible = true
			},
			//确认新增
			subAdd() {
				this.$refs.addForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null){clearTimeout(timer);}
        				timer = setTimeout(() => {
							var url = 'service_banner/addOrUpdate';
							that.addForm.img_json = JSON.stringify(that.addForm.img_list);
							let params = that.addForm;
							that.fd_post(url, params).then((res) => {
								if(res.status) {
									that.addVisible = false;
									that.$refs.addForm.resetFields();
									that.$message.success('操作成功');
									that.getBannerList();
								} else {
									that.$message.error(res.msg);
								}
							}).catch((err) => {
								that.$message.error('网络错误');
							});
						},1000);
					}
				});
			},
			//修改上架
			changeState(item) {
				var url = 'service_banner/updateOnSale';
				let params = {
					banner_id: item.banner_id,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						this.$message.success("操作成功");
					} else {
						this.$message.error(res.msg);
					}
				}).catch((err) => {
					this.$message.error('网络错误');
				});
			},
			//打开编辑对话框
			editLevel(levelInfo) {
				this.title = "编辑轮播图";
				this.addVisible = true
        this.addForm = JSON.parse(JSON.stringify(levelInfo))
				// for(let k in levelInfo) {
				// 	this.addForm[k] = levelInfo[k]
				// }
        console.log(this.addForm)
			},
			//删除
			removeLevel(item) {
				this.$confirm('你确认要删除吗?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'service_banner/deleteBanner';
					let params = {
						banner_id: item.banner_id,
					};
					this.fd_post(url, params).then((res) => {
						if(res.status) {
							this.$message.success('删除成功');
							this.getBannerList();
						} else {
							this.$message.error(res.msg);
						}
					}).catch((err) => {
						this.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消删除')
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getBannerList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getBannerList();
			},
      delCoverItem(index){
        this.addForm.img_list.splice(index,1)
      },
			openUploadDialog(count,pictureType){
        this.pictureType = pictureType
        this.chooseImgCount = count
        this.uploadVisible=  true
      },
      closeDelDialog(){
        this.uploadVisible = false
      },
      receiveRes(chooseList,pictureType){
        if(pictureType==='cover'){
          this.addForm.img_list = chooseList;
        }
        this.uploadVisible = false;
      },
		},
	}
</script>

<style lang="scss" scoped>
	.el-row{
	  text-align: left;
	  margin-bottom: 20px;
	}
	.previewUpload{
	  display: flex;
	  flex-wrap: wrap;
	  .previewItems{
	    width: 178px;
	    height: 178px;
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    margin:0 10px 10px 0;
	    cursor: pointer;
	    position: relative;
	    .previewadd {
	      font-size: 28px;
	      color: #8c939d;
	      width: 178px;
	      height: 178px;
	      line-height: 178px;
	      text-align: center;
	    }
	    &:hover{
	      border-color: #409EFF;
	    }
	  }
	  .previewItem{
	    width: 178px;
	    height: 178px;
	    border-radius: 6px;
	    margin:0 10px 10px 0;
	    cursor: pointer;
	    position: relative;
	    .delIcon{
	      display: none;
	    }
	    &:hover{
	      .delIcon{
	        display: block;
	        position: absolute;
	        top: 5px;
	        right: 5px;
	        color: white;
	        font-size: 30px;
	      }
	    }
	    
	    img{
	      width: 100%;
	      height: 100%;
	    }
	    
	  }
	}
</style>